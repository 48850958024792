import 'core-js/stable'
import Vue from 'vue'
import _ from 'lodash'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/store'
import { i18n } from './i18n'
import config from './config'
import WebatySidebarNavItem from './views/components/WebatySidebarNavItem'
// eslint-disable-next-line no-unused-vars
import touchHotReload from './touchHotReload'
import LongPress from 'vue-directive-long-press'
import '../public/assets/icons_font/wy-icon.css'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.prototype._ = _
Vue.prototype.$config = config
Vue.prototype.$logout = (vue) => {
  vue.$store.commit('unsetUser');
  vue.$router.push({ name: 'Login' });
}

Vue.component('WebatySidebarNavItem', WebatySidebarNavItem);
Vue.directive('long-press', LongPress);

new Vue({
  el: '#app',
  store,
  router,
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
