import _ from 'lodash';
import localStorage from 'local-storage';

export default {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    user: !_.isNull(localStorage.get('user')) ? localStorage.get('user') : '',
    forceGlobalLoadingIsEnabled: true,
    globalLoadingIsEnabled: false,
    globalError: '',
    languageCode: 'ar',
    confirmationModalIsOpen: false,
    confirmationModalMessage: '',
    confirmationModalOnOk: null,
    globalAuthorizationError: '',
    toastMessage: '',
    toastType: '',
    workerIsOffline: !_.isNull(localStorage.get('workerIsOffline')) ? localStorage.get('workerIsOffline') : true,
    workerOfflineData: !_.isNull(localStorage.get('workerOfflineData')) ? localStorage.get('workerOfflineData') : '',
    workerOfflineInvoices: !_.isNull(localStorage.get('workerOfflineInvoices')) ? localStorage.get('workerOfflineInvoices') : [],
}
