export default {
    isProduction: true,
    profile: {
        // profile_almashreq || profile_alhammad
        name: 'profile_almashreq',
    },
    defaultErrorStatusCode: 422,
    debug: false,
    phpXdebug: false,
}
