import Vue from 'vue'
import Router from 'vue-router'
import _ from 'lodash';
import routes from './routes';
import store from '../store/store'

const patchRouterMethod = (router, methodName) => {
    router[`old${methodName}`] = router[methodName];
    router[methodName] = function (location, onResolve, onReject) {
        if (onResolve || onReject) {
            return router[`old${methodName}`].call(this, location, onResolve, onReject)
        }

        return router[`old${methodName}`].call(this, location).catch((error) => {
            if (_.has(error, 'name') && error.name === 'NavigationDuplicated') {
                return this.currentRoute;
            }
            return Promise.reject(error)
        })
    };
};

Vue.use(Router)

const router = new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes
})

patchRouterMethod(router, 'push');
patchRouterMethod(router, 'replace');

router.beforeEach((to, from, next) => {
    if (!_.has(to, 'meta.requiresAuth')) {
        to.meta.requiresAuth = false
    }

    let nextData = {};
    if (!to.params.languageCode && !to.name) {
        to.params.languageCode = store.state.languageCode;
        nextData = {name: 'Home', params: to.params}
    } else if (_.indexOf(['ar', 'en'], to.params.languageCode) <= -1) {
        to.params.languageCode = store.state.languageCode
        nextData = {name: 'Home', params: to.params}
    } else {
        store.commit('changeLanguage', to.params.languageCode)
    }

    if (_.isEmpty(store.state.user) && to.meta.requiresAuth) {
        nextData = {name: 'Login', params: to.params}
    }

    if (!_.isEmpty(store.state.user) && !to.meta.requiresAuth) {
        nextData = {name: 'UserHome', params: to.params}
    }

    if (!_.isEmpty(nextData)) {
        next(nextData);
    } else {
        next();
    }

});
export default router

