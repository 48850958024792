<template>
  <div class="global-loader-background" v-if="enabled">
    <span class="global-loader-helper"></span>
    <img class="global-loader" src="~@/assets/images/global_loader.svg" alt="">
  </div>
</template>

<script>
export default {
  name: 'GlobalLoader',
  props: {
    action: Function,
    enabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    start() {
      this.enabled = true;
    },
    stop() {
      this.enabled = false;
    },
  },
};
</script>

<style lang="scss">
@keyframes stretch {
  0% {
    transform: scale(.4);
    //transform: scale(.4) rotate(5deg);
  }

  50% {
  }

  100% {
    // transform: rotate(-5deg);
  }
}
@keyframes stretch-2 {
  0% {

  }

  50% {

  }

  100% {
    transform: scale(.4);
  }
}

.global-loader-background {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //background-color: rgba(255,255,255,0.7);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  text-align: center;

  img.global-loader {
    vertical-align: middle;
    height: 250px;
    margin: 0 auto;
    animation-name: stretch;
    animation-duration: 800ms;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;

    &.global-loader-2 {
      animation-name: stretch-2;
    }
  }

  .global-loader-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
</style>
