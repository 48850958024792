import localStorage from 'local-storage';
import _ from "lodash";

export default {
    toggleSidebarDesktop (state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, [variable, value]) {
        state[variable] = value
    },
    setUser (state, user) {
        state['user'] = user
        localStorage.set('user', user)
    },
    unsetUser (state) {
        state['user'] = ''
        localStorage.remove('user')
    },
    enableGlobalLoading (state) {
        state['globalLoadingIsEnabled'] = true
    },
    disableGlobalLoading (state) {
        state['globalLoadingIsEnabled'] = false
    },
    pauseGlobalLoading (state) {
        state['forceGlobalLoadingIsEnabled'] = false
    },
    resumeGlobalLoading (state) {
        state['forceGlobalLoadingIsEnabled'] = true
    },
    setGlobalError (state, globalError) {
        state['globalError'] = globalError
    },
    clearGlobalError (state) {
        state['globalError'] = ''
    },
    changeLanguage (state, languageCode) {
        state['languageCode'] = languageCode
    },
    confirmation (state, payload) {
        state['confirmationModalIsOpen'] = true
        state['confirmationModalMessage'] = payload.message
        state['confirmationModalOnOk'] = payload.onOk
    },
    closeConfirmationModal (state) {
        state['confirmationModalIsOpen'] = false
        state['confirmationModalMessage'] = ''
        state['confirmationModalOnOk'] = null
    },
    setGlobalAuthorizationError (state, globalAuthorizationError) {
        state['globalAuthorizationError'] = globalAuthorizationError
    },
    clearGlobalAuthorizationError (state) {
        state['globalAuthorizationError'] = ''
    },
    showInfoToast (state, message) {
        state['toastMessage'] = message
        state['toastType'] = 'info'
    },
    clearToastMessage (state) {
        state['toastMessage'] = ''
        state['toastType'] = ''
    },
    setWorkerIsOffline (state) {
        state['workerIsOffline'] = true;
        localStorage.set('workerIsOffline', true);
    },
    setWorkerIsOnline (state) {
        state['workerIsOffline'] = false;
        localStorage.set('workerIsOffline', false)
    },
    setWorkerOfflineData (state, data) {
        state['workerOfflineData'] = data;
        localStorage.set('workerOfflineData', data);
    },
    addWorkerOfflineInvoice (state, invoice) {
        state['workerOfflineInvoices'].push(invoice);
        localStorage.set('workerOfflineInvoices', state['workerOfflineInvoices']);
    },
    removeFirstWorkerOfflineInvoice (state) {
        const workerOfflineInvoices = _.cloneDeep(state['workerOfflineInvoices']);
        workerOfflineInvoices.shift();
        state['workerOfflineInvoices'] = workerOfflineInvoices;
        localStorage.set('workerOfflineInvoices', state['workerOfflineInvoices']);
    },
}
