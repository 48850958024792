<template>
  <div>

    <div class="wy-top-notification" v-if="hasGlobalError">
      <div class="wy-top-notification-close" @click="$store.commit('clearGlobalError')">
        <CIcon name="cil-x-circle" :height="20"/>
      </div>
      {{ $store.state.globalError }}
    </div>

    <global-loader :enabled="globalLoading"/>
    <router-view></router-view>
  </div>
</template>

<script>
import globalLoader from './views/components/GlobalLoader.vue'

export default {
  name: 'App',
  components: {
    globalLoader,
  },
  data() {
    return {
    }
  },
  watch: {
    '$store.state.languageCode': function (languageCode) {
      this.changeLanguage(languageCode)
    }
  },
  computed: {
    globalLoading() {
      return this.$store.state.globalLoadingIsEnabled
    },
    hasGlobalError() {
      return !this._.isEmpty(this.$store.state.globalError)
    }
  },
  mounted() {
    this.changeLanguage(this.$store.state.languageCode)
  },
  methods: {
    changeLanguage(languageCode) {
      this.$root.$i18n.locale = languageCode
      document.getElementsByTagName('html')[0].dir = this.$t('DIR')
      this.$store.commit('clearGlobalError')
    }
  },
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
