import VueI18n from 'vue-i18n'
import en from "./locales/en.json";
import ar from "./locales/ar.json";
import Vue from "vue";

Vue.use(VueI18n)

const messages = {
    en,
    ar,
}

const i18n = new VueI18n({
    locale: 'en',
    messages
})

export { VueI18n, i18n }
