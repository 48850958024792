import _ from 'lodash';

export default [
    {
        path: '/:languageCode',
        redirect: '/:languageCode/login',
        name: 'Home',
        component: () => import('@/containers/TheLogin'),
        children: [
            {
                path: 'login',
                name: 'Login',
                meta: {
                    requiresAuth: false,
                    label: 'login',
                },
                component: () => import('@/views/auth/Login'),
            },
            {
                path: 'forgetPassword',
                name: 'ForgetPassword',
                meta: {
                    requiresAuth: false,
                    label: 'forgot_password',
                },
                component: () => import('@/views/auth/ForgetPassword'),
            },
        ]
    },
    {
        path: '/:languageCode/pages',
        redirect: '/:languageCode/user_home',
        name: 'pages',
        meta: {
            requiresAuth: true,
            label: 'dashboard',
        },
        component: () => import('@/containers/TheContainer'),
        children: [
            {
                path: 'user_home',
                name: 'UserHome',
                meta: {
                    requiresAuth: true,
                    label: '',
                },
                component: () => import('@/views/pages/UserHome'),
            },
            {
                path: 'under_construction',
                name: 'UnderConstruction',
                meta: {
                    requiresAuth: true,
                    label: 'under_construction',
                },
                component: () => import('@/views/pages/UnderConstruction'),
            },
            {
                path: 'change_password',
                name: 'ChangePassword',
                meta: {
                    requiresAuth: true,
                    label: 'change_password',
                },
                component: () => import('@/views/pages/ChangePassword'),
            },
            {
                path: 'users',
                name: 'UserContainer',
                meta: {
                    requiresAuth: true,
                    label: 'users',
                },
                component: () => import('@/views/pages/user_pages/UserContainer'),
                children: [
                    {
                        path: 'list',
                        name: 'Users',
                        meta: {
                            requiresAuth: true,
                            label: '',
                        },
                        component: () => import('@/views/pages/user_pages/Users'),
                    },
                    {
                        path: 'form/:user_id?',
                        name: 'User',
                        meta: {
                            requiresAuth: true,
                            label: '',
                        },
                        component: () => import('@/views/pages/user_pages/User'),
                        beforeEnter: (to, from, next) => {
                            if (_.isUndefined(to.params.user_id)) {
                                to.meta.label = 'add_new_user'
                            } else {
                                to.meta.label = 'edit_user'
                            }
                            next();
                        },
                    },
                ]
            },
            {
                path: 'customers',
                name: 'CustomerContainer',
                meta: {
                    requiresAuth: true,
                    label: 'customers',
                },
                component: () => import('@/views/pages/customer_pages/CustomerContainer'),
                children: [
                    {
                        path: 'list',
                        name: 'Customers',
                        meta: {
                            requiresAuth: true,
                            label: '',
                        },
                        component: () => import('@/views/pages/customer_pages/Customers'),
                    },
                    {
                        path: 'form/:customer_id?',
                        name: 'Customer',
                        meta: {
                            requiresAuth: true,
                            label: '',
                        },
                        component: () => import('@/views/pages/customer_pages/Customer'),
                        beforeEnter: (to, from, next) => {
                            if (_.isUndefined(to.params.customer_id)) {
                                to.meta.label = 'add_new_customer'
                            } else {
                                to.meta.label = 'edit_customer'
                            }
                            next();
                        },
                    },
                ]
            },
            {
                path: 'products',
                name: 'ProductContainer',
                meta: {
                    requiresAuth: true,
                    label: 'products',
                },
                component: () => import('@/views/pages/product_pages/ProductContainer'),
                children: [
                    {
                        path: 'list',
                        name: 'Products',
                        meta: {
                            requiresAuth: true,
                            label: '',
                        },
                        component: () => import('@/views/pages/product_pages/Products'),
                    },
                    {
                        path: 'form/:product_id?',
                        name: 'Product',
                        meta: {
                            requiresAuth: true,
                            label: '',
                        },
                        component: () => import('@/views/pages/product_pages/Product'),
                        beforeEnter: (to, from, next) => {
                            if (_.isUndefined(to.params.product_id)) {
                                to.meta.label = 'add_new_product'
                            } else {
                                to.meta.label = 'edit_product'
                            }
                            next();
                        },
                    },
                ]
            },
            {
                path: 'gas_stations',
                name: 'GasStationContainer',
                meta: {
                    requiresAuth: true,
                    label: 'gas_stations',
                },
                component: () => import('@/views/pages/gas_station_pages/GasStationContainer'),
                children: [
                    {
                        path: 'list',
                        name: 'GasStations',
                        meta: {
                            requiresAuth: true,
                            label: '',
                        },
                        component: () => import('@/views/pages/gas_station_pages/GasStations'),
                    },
                    {
                        path: 'form/:gas_station_id?',
                        name: 'GasStation',
                        meta: {
                            requiresAuth: true,
                            label: '',
                        },
                        component: () => import('@/views/pages/gas_station_pages/GasStation'),
                        beforeEnter: (to, from, next) => {
                            if (_.isUndefined(to.params.gas_station_id)) {
                                to.meta.label = 'add_new_gas_station'
                            } else {
                                to.meta.label = 'edit_gas_station'
                            }
                            next();
                        },
                    },
                ]
            },
            {
                path: 'invoices',
                name: 'InvoiceContainer',
                meta: {
                    requiresAuth: true,
                    label: 'invoices',
                },
                component: () => import('@/views/pages/invoice_pages/InvoiceContainer'),
                children: [
                    {
                        path: 'list',
                        name: 'Invoices',
                        meta: {
                            requiresAuth: true,
                            label: '',
                        },
                        component: () => import('@/views/pages/invoice_pages/Invoices'),
                    },
                    {
                        path: 'form/:invoice_id?',
                        name: 'Invoice',
                        meta: {
                            requiresAuth: true,
                            label: '',
                        },
                        component: () => import('@/views/pages/invoice_pages/Invoice'),
                        beforeEnter: (to, from, next) => {
                            if (_.isUndefined(to.params.invoice_id)) {
                                to.meta.label = 'add_new_invoice'
                            } else {
                                to.meta.label = 'edit_invoice'
                            }
                            next();
                        },
                    },
                ]
            },
            {
                path: 'settings',
                name: 'Settings',
                meta: {
                    requiresAuth: true,
                    label: 'settings',
                },
                component: () => import('@/views/pages/Settings'),
            },
        ]
    },
    {
        path: '/:languageCode/worker',
        redirect: '/:languageCode/worker/new_worker_invoice',
        name: 'WorkerHome',
        meta: {
            label: 'invoices',
        },
        component: () => import('@/containers/TheWorker'),
        children: [
            {
                path: 'new_worker_invoice',
                name: 'new_worker_invoice',
                meta: {
                    requiresAuth: true,
                    label: 'new_invoice',
                },
                component: () => import('@/views/worker/NewWorkerInvoice'),
            },
        ]
    },
]
